$border-calendar: rgba(151, 151, 151, 0.24);

.cal-month-view,
.cal-week-view {
  background-color: $dark-2;
}
/** Month view, cell hover style **/
.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: $dark-2;
  color: $white;
  &:hover {
    color: $white;
  }
  opacity: 1;

  .cal-day-number {
    color: $white;
    opacity: 1;
    &:hover {
      color: $white;
    }
  }
}
/**  Week view, header today cell style **/
.cal-week-view .cal-header.cal-today {
  background-color: $primary;

  .cal-day-number {
    opacity: 1;
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

/** Month view cell today style **/

.cal-month-view .cal-day-cell.cal-today,
.cal-month-view .cal-cell.cal-has-events.cal-open.cal-today {
  background-color: $dark-2;
  &:hover {
    background-color: $dark-2;
    .cal-day-number {
      color: $primary;
    }
  }
  .cal-day-number {
    color: $primary;
  }
}

/** Month view, disable background on hover **/
mwl-calendar-month-cell {
  background-color: transparent !important;
}

/** Month view today header style **/
.cal-month-view .cal-header .cal-cell.cal-today {
  background-color: $dark-2;
}

/** day number in month view cell **/
.cal-month-view .cal-day-number {
  color: $white;
  opacity: 1;
  &:hover {
    opacity: 1;
    color: $white;
  }
}

/** Week view, header hover style **/
.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
  background-color: $dark-2;
  color: $white;
}

/** Month view, Week view, week days style and header week style **/
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number,
.cal-week-view .cal-header.cal-weekend span,
.cal-month-view .cal-day-cell.cal-weekend:hover .cal-day-number {
  color: $red;
  opacity: 1;
}

/** Month view, cell base style **/
.cal-day-cell:not(.cal-has-events) {
  color: $light-color;
}

/** Month view, recap shifts **/
.cal-month-view .cal-open-day-events {
  font-size: 0.875rem;
  box-shadow: none;
}

/** Disable drag in month view **/
.cal-month-view .cal-draggable {
  cursor: default;
  pointer-events: none;
}
/** Month viw, day out of month style **/
.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.3;
}

/** Month view, Row hover style **/
.cal-month-view .cal-cell-row:hover {
  background-color: transparent;
}

/** Month view hover color weekend days **/
.cal-month-view .cal-cell-row .cal-cell.cal-weekend:hover {
  .cal-day-number {
    color: $red;
  }
}
/** Week view, hover style **/
.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
  background-color: $primary;
  cursor: pointer;
}

.cal-week-view .cal-hour {
  background-color: $bg-color-generic;
}

/** View Week odd / last cell style **/
.cal-week-view .cal-hour-odd {
  background-color: rgba(255, 255, 255, 0.04);
}

/** Week view border styles **/
.cal-week-view {
  border-top: 0;
}

/** Week view, event container **/

.cal-week-view .cal-time-events {
  border-color: transparent;
}


.cal-week-view .cal-time-events .cal-event {
  padding: 0;
}


/** Week view header style **/
.cal-week-view .cal-day-headers {
  border: none;
  border-bottom: 2px inset $border-calendar;
  background-color: $bg-color-generic;
}

.cal-week-view .cal-day-headers .cal-header:not(:last-child), 
.cal-week-view .cal-day-headers .cal-header:first-child {
  border: none;
}

/** Style header today title **/
.cal-week-view {
  .cal-day-headers {
    .cal-header {
      &:hover {
        background-color: $dark-2;
      }
      &.cal-today {
        color: $white;
        background-color: $primary;
      }
    }
  }
}

/** Week view column color **/
.cal-week-view .cal-day-column {
  border-left-color: $border-calendar;
}

/** Week view border hours cell **/
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment, .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom: 1px inset $border-calendar;

}

/** Week view, all day event */
.cal-week-view .cal-all-day-events {
  border-color: $dark-2;
  border-bottom-width: 2px;
}

/** Style title cell week view **/
.cal-event-title {
  color: $white !important;
}

/** Style for overlappig event **/
.invalid-position .cal-event {
  background-color: $red !important;
  color: $white;
}
