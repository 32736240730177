@mixin centerElement($type: absolute, $horizontal: true, $vertical: true) {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  @if $type==absolute {
    position: absolute;
  }

  @else {
    position: fixed;
  }
}

@mixin resetAppearance() {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

@mixin iconInsideInputStyle() {
  @include centerElement();
  left: calc(100% - 1rem);
  pointer-events: none;
  font-size: 1.5rem;
}

@mixin inputStyleDark() {
  @include resetAppearance();
  background-color: transparent;
  box-shadow: inset 0 -2px 0 0 rgba($white, 0.32);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
}

@mixin inputStyle() {
  background-color: rgba($white, 0.04);

  select.form-control,
  input.form-control {
    @include inputStyleDark();

    &:hover,
    &:active,
    &:focus {
      @include inputStyleDark();
    }

    &::placeholder,
    &:invalid {
      color: rgba($white, 0.4);
    }

    option {
      color: $black-bg;
    }
  }
}


@mixin inputStyleWithIcon() {
  @include inputStyle();
  position: relative;

  >fa-icon {
    @include iconInsideInputStyle();
  }
}

@mixin selectSearchableStyle() {
  color: $white !important;
  background-color: $bg-color-dark !important;
}

.form__label {
  margin-bottom: 1rem;
  line-height: 1.5rem;
}

.input__label {
  font-size: 0.875rem;
  line-height: 1.5rem;
  opacity: 0.5;
}

.label--dark {
  @extend .form__label;
  text-transform: none;
}

.label--small {
  @extend .form__label;
  font-size: 0.875rem;
}

.label--grey-out {
  @extend .form__label;
  opacity: 0.4;
}

.form__title {
  padding-top: 1.5rem;
}

form {
  .input-group__container {
    &:first-child {
      margin-top: 1.5rem;
    }
  }
}

.input-group__container {
  margin-top: 2.5rem;

  .visup-select-label {
    margin-bottom: 0.5rem !important;
  }

  >visup-select {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.input-range__container {
  display: flex;
  align-items: center;
}

.input--disabled {
  .visup-input-area-cont {
    pointer-events: none;
    opacity: 0.4;
  }
}
.input--no-clear {
  .ng-fa-icon {
    display: none;
  }
}
.select--disabled {
  pointer-events: none;
}

.select--dark {
  @include inputStyleWithIcon();
}


.input--dark {
  @include inputStyle;
}

.input__datepicker--dark {
  @include inputStyleWithIcon;

  >fa-icon {
    font-size: 1rem;
  }
}

.select--outline {
  @include inputStyleWithIcon();
  background: transparent;
  border: 1px solid $white;
  border-radius: 10rem;

  select {
    &.form-control {
      box-shadow: none;

      &:hover,
      &:visited,
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.input--outline-round {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin-right: 1rem;
  border: 1px solid $white;
  border-radius: 10rem;
  opacity: 0.4;

  input {
    padding: 0.25rem;
    border: 0;
  }
}

:host {
  form {
    visup-input {
      ::ng-deep {
        .visup-input {
          ::ng-deep {
            .visup-input-area-cont {
              flex-direction: row-reverse;
            }
          }
        }
      }
    }
  }
}

visup-input-left-area {
  border-left: 0;
}

.disabled {
  opacity: 0.4;
  cursor: none;
}

.custom-form {
  form {
    background-color: transparent;
  }

  .input__container {
    @include autofillStyle();
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    width: 100%;
    border: 0;
    height: auto;
    margin-bottom: 2.35rem;
    padding: 0.5rem 0 0.5rem 0.5rem !important;
    border-bottom: 2px solid #777777;
    background-color: rgba($white, 0.04);

    input {
      width: 100%;
      font-size: 1rem;
      outline: 0;
      border: 0;
      border-radius: 0;
      color: $white !important;
      transition: border-color .4s;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: transparent !important;
      -webkit-appearance: none;
    }

    fa-icon {
      display: flex;
      align-items: center;
      padding: 0 1.5rem 0 0;
      font-size: 1rem;
    }

    &.is-invalid {
      border-color: $red2 !important;
    }
  }

  .input__wrapper {
    position: relative;
  }

  .input-error {
    position: absolute;
    left: 0;
    bottom: -1rem;
    margin: 0;
    font-size: .5rem;
    color: $red2 !important;
    text-align: left;
  }
}

.field-error {
  border-bottom: 2px solid $red2 !important;
}

.field-error-msg {
  left: 0;
  bottom: -1rem;
  margin: 0;
  font-size: .5rem;
  color: $red2 !important;
  text-align: left;
}
// TODO MOVE TO SEPARATE FILE

.ng-select.custom {
  &:focus {
    outline: none !important;
  }

  .ng-dropdown-panel {
    @include customScrollBar();
    @include selectSearchableStyle();
    padding: 0.25rem;
    line-height: 1.5rem;
    border-radius: 0 0 1.875rem 1.875rem;

    .scroll-host {
      @include hideScrollBar();
    }
  }

  .ng-dropdown-panel-items {
    border-radius: 0 0 1.875rem 1.875rem;

    .ng-option {
      @include selectSearchableStyle();
      height: 2.375rem;

      &.ng-option-marked {
        color: $primary !important;
        background-color: var(--vu-color-transparent-2) !important;
        transition: opacity .2s;
      }
    }
  }

  .ng-select-container {
    @include selectSearchableStyle();
    padding-left: 0.5rem;
    border-radius: 1.3rem;
    border-color: $white !important;
    min-height: 2.8rem;
    height: 2.8rem;
  }

  .ng-input {
    padding-top: 0.15rem;
    &:focus {
      outline: none !important;
    }

    input {
      color: $white !important;
      padding-left:  0.5rem !important;
    }
  }

  &.ng-select-opened {
    .ng-value {
      opacity: 0.4 !important;
    }

    .ng-arrow-wrapper {
      top: 0;
      @include mobile() {
        top: 2px;
      }
    }

    .ng-arrow {
      transform: rotate(-180deg) !important;
      transform-origin: center !important;
    }

    .ng-select-container {
      border-color: $white !important;
    }

    .ng-optgroup {
      color: #666666;
      font-size: 0.9rem;
      height: 2.375rem;
    }
  }

  .ng-arrow-wrapper {
    width: 1.625rem;
    height: 1.5rem;
    padding-right: 0.625rem;
    right: 3px;
    top: 3px;
    transition: all 0.2s;
    @include mobile() {
      top: -1px;
    }
    .ng-arrow {
      width: 100%;
      height: 100%;
      border: 0 !important;
      transition: all 0.2s;
      &:before {
        content: url('../../assets/images/arrow-down.svg');
      }
    }
  }
}

.ng-select.arrows {
  flex: 1;
  .ng-select-container {
    border-radius: 0 !important;
  }
  .ng-value-container {
    margin-right: 1rem;
  }
  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      border-radius: 0 0 1.875rem 1.875rem;
    }
    padding: 0.25rem 0;
    .ng-option {
      padding: 8px 18px;
    }
    .ng-option-selected {
      color: $blue-dark !important;
    }
  }
}

.ng-select {

  &.disabled {

    .ng-select-container {
      background-color: rgba(255,255,255, 0.16) !important;
      border-color: transparent !important;
      opacity: 0.4;
    }
  }
}

.btn__nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  border-radius: 0 160px 160px 0;
  border: 1px solid $white;
  cursor: pointer;
  fa-icon {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }
}
.btn__nav--left, .btn__nav--right  {
  @extend .btn__nav;
  min-width: 2.5rem;
  color: $white;
  border-left: 0 !important;
  &:hover {
    color: $white;
  }
}
.btn__nav--left {
  transform: rotate(180deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  perspective: 0;
}

.ng-select-top {

  .ng-dropdown-panel,
  .ng-dropdown-panel-items {
    border-radius: 1.875rem 1.875rem 0 0 !important;
  }
}

.ng-select.blue {
  @extend .custom;

  .ng-select-container {
    color: $white !important;
    background-color: $primary !important;
    .ng-placeholder {
      font-size: 1rem !important;
    }
  }

  .ng-arrow-wrapper {
    height: auto !important;
    transform-origin: center;
    top: 0;
  }

  .ng-dropdown-panel-items {
    .ng-option {
      font-size: 1.5rem;
      font-weight: 300;
      background-color: $primary !important;
    }
  }

  .ng-dropdown-panel {
    background-color: $primary !important;
  }
}

.rect {
  .ng-dropdown-panel, .ng-dropdown-panel-items  {
    border-radius: 0 !important;
  }
}

.ng-select.custom-modal-form {
  width: 100%;
  border-bottom: 2px solid $grey !important;

  .ng-select-container {
    border: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
    .ng-value-container {
      padding-left: 1rem;
      .ng-input {
        top: 0.5rem;
        padding-left: 0;
        input {
          padding-left: 1rem !important;
        }
      }
    }
  }


  .ng-dropdown-panel {
    border: 0 !important;
    border-radius: 0 !important;
    padding: 0;

    .ng-dropdown-panel-items {
      border-top: 2px solid $blue-dark !important;
      border-radius: 0 !important;
      .ng-option {
        border-radius: 0 !important;
        padding: 0.56rem 1rem;
        height: auto !important;
        &.ng-option-selected {
          color: $blue-dark !important;
        }
        &:last-child {
          border-radius: 0 !important;
        }
      }
    }
  }
  .ng-select-focused {
    border: 0;
  }
  &.select--disabled {
    opacity: 0.4;
  }


  &.field-error {
    border-bottom: 2px solid $red2 !important;
  }
}
.select-label {
  margin-top: .5rem;
  margin-bottom: .25rem;
}
