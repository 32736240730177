.settings {
  .toggle__container {
    display: flex;
    align-items: center;
  }

  .label--small {
    margin-top: 0.5rem;
  }

  // TODO REMOVE DUPLICATED CODE

  .form__label {
    font-weight: bold;
  }

  .label {
    margin-bottom: 0.5rem;
    font-weight: 300;

    visup-select  {
      ::ng-deep {
        .visup-select {
          ::ng-deep {
            .visup-button {
              min-width: auto;
            }
          }
        }
      }
    }
    visup-select, .input-range__container {
      padding: 0 !important;
    }

    .input-range__container {
      visup-select {
        width: 50%;
      }
    }
  }
}
