.list__header {
  display: flex;
  margin-bottom: 2rem;
}

.actions__container {
  display: inline-flex;
  justify-content: flex-end;
  flex: 1;
}

.counter__container {
  display: inline-flex;
  flex: 0.5;
  align-items: baseline;
}

.counter {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.counter__text {
  text-transform: none;
}


// OVERRIDE VISUP-UI LIBRARY

visup-table {
  visup-td {
    &:last-child {
      .text-cont {
        @include fixAlignIcon();

        overflow: visible;
      }
    }
    .text-cont {
      word-break: unset;
    }
  }
}

.btns__container {
  display: flex;
  align-items: center;
  visup-button-outline {
    margin-right: 1.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
  fa-icon {
    margin-right: 1rem;
  }
  &.slim {
    padding: 0.5rem 0.75rem;
    text-transform: uppercase;
  }
  .action {
    cursor: pointer;
    margin-right: 3rem;
  }
}

.btn-actions__container {
  display: flex;
  align-items: center;
  visup-button-outline {
    flex: 1;
    margin: 2rem 1.5rem 2rem 0;
    max-height: 3rem;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      button {
        border-color: rgba($primary, 1) !important;
        transition: all 0.3s ease;
      }
    }
    .visup-button {
      display: inline-block;
      border: 1px solid rgba($white, 0.16) !important;
      width: 100%;
      font-size: 1.25rem;
      line-height: 2rem;
      text-transform: none;
      padding: 0.5rem;
    }
  }
}

:host {
  .no-actions {
    ::ng-deep {
      > div {
        opacity: 0.4;
      }
    }
  }
}

.inuse-msg {
  margin: 1rem 0 2rem 0;
}

.list--big {
  visup-th {
    background-color: transparent !important;
  }
  visup-tr {
    background-color: transparent !important;
    box-shadow: inset 0 -1px 0 0 rgba($white,0.16);
  }
}