@import "variables";

$font-family-sans-serif: 'museo-sans';
$modal-content-bg: $bg-modal;
$modal-content-border-width: 0;
$modal-backdrop-bg: $black-bg;
$modal-backdrop-opacity: 0.8;
$modal-header-border-width: 0;
$input-color: $white;
$tooltip-arrow-color: rgba(69, 70, 69, 1);
$theme-colors: (primary: $primary, secondary: $dark-grey, tertiary: $grey, light: $white, dark: $black, dodger-blue: $dodger-blue, tundora: $tundora, black: $black-bg);

@import "bootstrap/scss/bootstrap.scss";

.btn {
  cursor: pointer;
}

.modal-fullscreen {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0;
  .modal-content {
    border-radius: 0;
    height: 100%;
    .modal-header {
      border-radius: 0;
    }
  }
}
