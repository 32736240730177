.no-data {
  &:not(visup-list-ranking) {
    position: relative;
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &:after {
    content: 'No data available.';
    width: 100%;
    text-align: center;
  }
  [class*="text"] {
    display: none;
  }
}

.disabled {
  opacity: 0.4;
}

.no-data-dashboard {
  opacity: 0.48;
}