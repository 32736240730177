.icon--round {
  @include round-bg(2rem, 2rem, transparent, border, 1px, $white);
  &.show {
    background-color: $black-bg;
    border: 0;
  }
}

.icon--round--small{
  @include round-bg(1.3rem, 1.3rem, transparent, border, 1px, $white);
  &.show {
    background-color: $black-bg;
    border: 0;
  }
}

.icon--big {
  font-size: 4rem;
}

.dropdown-menu {
  @include dropdown-style($white, $black-bg)
}

.dropdown-item {
  @include dropdown-style($white, $black-bg);
  padding: 0 1rem !important;
}

.check-icon, .status-icon, .icon--big {
  svg {
    height: 100%;
  }
}
