.btn-outline-light.order {
  border-radius: 20px;
  width: 120px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.btn, .btn-arrow {
  cursor: pointer;
}

.btn--grey-out {
  opacity: 0.64;
}

.btn--default {
  @extend .btn;
  padding: 0.5rem 3rem;
  text-transform: uppercase;
  cursor: pointer;
}

.btn-t--light {
  @extend .btn--default;
  @include btn--transparent-style($white);
}

.btn--round {
  border-radius: 50%;
}

.btn--round-a {
  border-radius: 1.25rem;
}

.btn-icon {
  @extend .btn;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid $white;
  border-radius: 50%;
  line-height: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.5rem;
}

.change-button {
  background-color: $blue;
  border: none;
  box-shadow: 1px 1px 14px 0 rgba($black-bg,0.3);
  border-radius: 20px;
  width: 80px;
  height: 24px;
  color: $white;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.section-button {
  background-color: $pink;
  box-shadow: 1px 1px 19px 0 rgba($black-bg,0.3);
  border-radius: 20px;
  //min-width: 130px
  height: 33px;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 20px;
  font-weight: 500;
}


.btn-time-modal {
  min-width: 200px;
  padding: 0;
  text-align: left;
  text-transform: none;
  @include media-breakpoint-down(md) {
    min-width: 156px;
  }
  span {
    padding: 0.5rem 1rem;
  }
}

.btn--rect {
  display: flex;
  align-items: center;
  width: 100%;
  height: 5rem;
  padding: 1.125rem 1rem;
  border: 1px solid $white;
  border-radius: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  fa-icon {
    opacity: 0.48;
    margin-right: 0.5rem;
    &.no-label {
      margin-right: 1rem;
    }
  }
  .label {
    font-size: 0.875rem;
    line-height: 1.25rem;
    opacity: 0.48;
    text-transform: none;
  }
  &.active {
    background-color: $primary;
    border: none;
    fa-icon {
      opacity: 1;
    }
  }
}
