$underlineColor: #444444;

.tab__item {
  margin-top: 3rem;
  .box-container {
    text-align: center;
  }

  .box-title {
    text-transform: uppercase;
    font-size: 0.875rem;
  }
  .box-value {
    font-size: 2rem;
  }
}

.tab__text {
  margin: 2rem 0;
}

.visup-tab-group {
  border-bottom: 1px solid $underlineColor !important;
.visup-tab-head {
    .text-area {
      a.title-area {
        color: rgba($white, 1);
        transition: all .4s;
        &:hover {
          color: rgba($white, 1);
          text-decoration: none;
        }
      }
    }
  }
}
