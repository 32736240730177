.tooltip {
  &.show {
    opacity: 0.9 !important;
  }
  .tooltip-inner {
    background-color: rgba(69, 70, 69, 1) !important;
    color: $white;
    min-width: 10rem;
  }
  .tooltip__container {
    min-width: 10rem;
    max-width: auto;
    padding: 0.5rem;
  }
  .tooltip__title {
    margin-bottom: 0.5rem;
    font-size: 1rem !important;
    text-transform: uppercase !important;
  }
  .tooltip__value {
    margin-bottom: 0;
    font-size: 1.5rem !important;
  }
}

.tooltip__icon {
  opacity: 1;
}