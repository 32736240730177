/* You can add global styles to this file, and also import other style files */
@import url("https://use.typekit.net/jcg3bho.css");
@import "bootstrap";
@import "variables";

@import "generic/generic";
@import "components/components";

@import "visup-ui";

html {
  font-size: 16px !important;
  scroll-behavior: smooth;
  min-height: 100%;
  @include media-breakpoint-down(sm) {
    font-size: 14px !important
  }
  @include media-breakpoint-down(xs) {
    font-size: 12px !important
  }
}

body {
  min-height: 100%;
  color: $white;
  background: $bg-color-generic;
  font-family: "museo-sans", sans-serif;
  background-image: url("../assets/images/Artboard-big.png");
  -webkit-font-smoothing: antialiased;
}
body {
  &.theme-light {
    background: $light-bg;
    color: $bg-color-generic;
  }

}

li {
  list-style: none;
  a {
    text-decoration: none;
  }
}

.clickable {
  cursor: pointer;
}

.toast-center {
  top: 20px;
  right: 0;
  width: 100%;
  &.toast {
    width: 300px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.bg-white-05 {
  background-color: rgba($white, 0.05)
}

.bg-dark {
  background-color: $black-bg !important
}

.text--big {
  font-size: 1.25rem;
  line-height: 1.25;
}

.border-bottom--highlight {
  border: 0;
  border-radius: inherit;
  border-bottom: 2px inset;
}

.custom-select {
  &.form-control-plaintext {
    padding-left: 0 !important;
    padding-right: 1.25rem !important;
    border-left: 0 !important;
    border-right: 0 !important;
    background-position-x: right;
  }
}

hr {
  background-color: var(--vu-color-grey);
}

.highlight-text {
  background-color: $idle;
  color: $dark-2;
}
