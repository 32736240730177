@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes zoomOut {
	from {
		opacity: 1;
    transform: scale(1);
  }

	to {
		opacity: 0;
    transform: scale(0);
  }
}
@keyframes progress {
  0% {
    width: 0 !important;
  }
  100% {
    width: auto;
  }
}

.closed {
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
  }

  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}

.opened {
  opacity: 1;

  .vertical {
    transition: all 0.3s ease-in-out;
    transform: rotate(90deg);
  }

  .horizontal {
    transition: all 0.3s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
  }
}

@mixin plusMinus($color) {
  .closed {
    .vertical {
      transition: all 0.5s ease-in-out;
      transform: rotate(-90deg);
    }
    .horizontal {
      transition: all 0.5s ease-in-out;
      transform: rotate(-90deg);
      opacity: 1;
    }
  }

  .opened {
    opacity: 1;
    .vertical {
      transition: all 0.5s ease-in-out;
      transform: rotate(90deg);
    }
    .horizontal {
      transition: all 0.5s ease-in-out;
      transform: rotate(90deg);
      opacity: 0;
    }
  }


  .circle-plus .circle {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      overflow: hidden;
  }
  .circle-plus .circle .horizontal {
      position: absolute;
      background-color: $color;
      width: 30px;
      height: 4px;
      left: 50%;
      margin-left: -15px;
      top: 50%;
      margin-top: -2.5px;
  }
  .circle-plus .circle .vertical {
      position: absolute;
      background-color: $color;
      width: 4px;
      height: 30px;
      left: 50%;
      margin-left: -2.5px;
      top: 50%;
      margin-top: -15px;
  }
}
