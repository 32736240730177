// TODO FIX FOR LIBRARY, I HAVE TO USE IMPORTANT

@mixin badge-classic-style($color) {
  color: $black-bg !important;
  border-color: none !important;
  background-color: rgba($color, 1) !important;
}

@mixin badge-neon-style($color) {
  color: $color !important;
  border-color: $color !important;
  background-color: rgba($color, 0.2) !important;
}

// TODO FIND A BETTER NAME IS NOT COMPLETELY SQUARE
.badge--square {
  .visup-badge {
    width: 100%;
    padding: 0.25rem !important;
    margin: 0;
    border-radius: 0.25rem !important;
    border: 1px solid;
    opacity: 1;
    color: $white;
    &.bg-danger {
      @include badge-neon-style($danger);
    }

    &.bg-success {
      @include badge-neon-style($success);
    }

    &.bg-warning {
      @include badge-neon-style($idle);
    }
  }

  &.classic {
    &.bold {
      text-transform: uppercase;
      .visup-badge {
        font-size: 0.875rem;
        line-height: 1rem;
        font-weight: 700;
      }
    }
    .visup-badge {
      &.bg-danger {
        @include badge-classic-style($danger);
      }

      &.bg-success {
        @include badge-classic-style($success);
      }

      &.bg-warning {
        @include badge-classic-style($idle);
      }
    }
  }
}

.badge--big {
	height: 7rem;
	width: 7rem;
}

.badge--red {
  background-color: rgba($red, 0.64);
  border: 0.5rem solid rgba($red, 1);
}

.badge--circle {
  border-radius: 50%;
}

.badge__highlight {
  @extend .badge--big;
  @extend .badge--circle;
  @extend .badge--red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}