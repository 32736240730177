$text-color: $white;
$icon-size: 1.5rem;

.modal-open {
  .modal {
    overflow-y: hidden !important;
  }
}

.modal-dialog {
  @include customScrollBar();
  pointer-events: all;
  overflow-y: auto !important;
  max-height: 100%;

}

.close-em {
  padding: 0;
  border: none;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  background-color: transparent;
  color: $text-color;
  cursor: pointer;
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    border: none;
    border-radius: 50%;
    background-color: $black-bg;
    &:focus {
      outline: none;
    }
  }
  &:focus {
    outline: none;
  }
}

.modal--open {
  overflow: hidden;
  .main-wrapper {
    padding-bottom: 5rem
  }
}
.select--open {
  .main-wrapper {
    padding-bottom: 5rem
  }
  .list__header, .btn-actions__container, .visup-tab-head, .settings-header__container {
    pointer-events: none;
  }
}

.modal {
  .btn__container {
    display: flex;
    margin-top: 4rem;
    justify-content: space-evenly;
    align-items: center;
  }
}
.modal--small, .modal-em {
  .modal-dialog {
    background: $bg-modal;
    max-width: 700px;
  }
  .modal-header, .modal-body {
    padding: 0;
  }
  .modal-body {
    padding-right: 2.5rem;
  }
  .modal-content {
    padding: 1.25rem 1.25rem 3rem 4rem;
    font-size: 1.25rem;
    color: $text-color;
  }
  .btn__container {
    display: flex;
    margin-top: 4rem;
    justify-content: space-between;
    align-items: center;
  }
}

.modal--medium {
  .modal-dialog {
    max-width: 588px !important;
  }
}

.modal--small {
  padding-bottom: 2rem;
  .modal-dialog {
    max-width: 488px;
  }
  .modal__container {
    margin-top: 2.5rem;
    padding-bottom: 0;
  }
  .form-group {
    margin-bottom: 0;
  }
  .input-group {
    margin-bottom: 1rem;
    visup-input, visup-select {
      width: 100%;
      margin-bottom: 0.5rem !important;
    }
  }

  label {
    margin-top: 2.5rem;
    &:first-child {
      margin-top: 0;
    }
  }
}

.modal--big {
  .modal-dialog {
    max-width: 50%;
  }
}

.modal__title {
  width: 100%;
  margin: 1.5rem 0 0 0;
  font-size: 1.5rem;
  text-align: center;
}

.modal__container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  font-size: 1rem;
  form {
    width: 100%;
  }
}


.btn__modal--remove {
  width: 100%;
  margin-top: 2rem;
  text-align: center;
  a:not([href]):not([tabindex]) {
    text-transform: none;
    text-decoration: underline;
    color: #fd0e35;
    cursor: pointer;
  }

  .modal__subtitle {
    text-transform: uppercase;
  }
}


.modal--blue {
  @include media-breakpoint-down(xl) {
    background: $primary;
  }

  .modal-dialog {
    height: 100%;
    margin: 0;
    max-width: inherit;
  }
  .modal-content {
    padding: 2rem;
    height: 100%;
    background: $primary;
    border-radius: 0;
    @include media-breakpoint-down(xl) {
      padding: 1rem 2rem;
    }
  }
  .modal-header {
    position: relative;
    display: block;
    padding: 0;
    .close {
      font-size: $icon-size;
      color: $white;
      opacity: 1;
      &:hover {
        opacity: 1;
      }
    }
  }
  .back {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    .text {
      font-size: 2.5rem;
      font-weight: lighter;
      margin-left: 1rem;
      @include mini-tablet-portrait {
        display: none;
      }
      @include mini-tablet-landscape {
        display: none;
      }
    }
  }
  .items {
    margin: 2rem auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 1170px;
    padding-bottom: 3rem;
    @include media-breakpoint-down(xl) {
      margin: 0 auto;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      min-height: 10rem;
      padding: 0 !important;
      text-align: center;
      border: 4px solid #FFFFFF;
      border-radius: 4px;
      font-size: calc(100% + 1.5vh);
      min-width: 400px;
      margin: 1.5rem;
      cursor: pointer;
      transition: background 200ms linear;
      @include media-breakpoint-down(xl) {
        padding: 3.5rem 0;
        margin: 1.5rem 1rem;
        width: 380px;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.2)
      }
      @include ipad-air-portrait() {
        min-width: auto;
        width: 40%;
      }
      @include ipad-mini-portrait() {
        min-width: auto;
        width: 40%;
      }
      @include mini-tablet-landscape() {
        min-width: auto;
      }

      &:first-child:nth-last-child(2), &:first-child:nth-last-child(2) .item {
        @include media-breakpoint-down(xl) {
          width: 408px;
        }
      }

      .next-level {
        margin-left: 1rem;
      }
    }
  }
  .item {
    h1 {
      font-size: inherit !important;
    }
  }

  .item-children {
    @include textElips(82%);
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin: 0 auto;
  }

  .icon--round {
    @include round-bg($icon-size * 2, $icon-size * 2, rgba(0,0,0,0.16), border, 0px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-cancel__container {
    text-align: center;
  }

  visup-button-outline {
    button {
      justify-content: center;
      text-transform: uppercase;
      min-width: 10rem;
    }
  }
}
