@use "sass:math";

@import 'bootstrap/scss/bootstrap-grid.scss';
@import 'variables';

$scrollbarBg: #f5f5f5;

@mixin btn--transparent-style($color) {
  background-color: transparent;
  border: 1px solid $color;
  color: $color;
  &:hover {
    color: $color;
  }
}

@mixin dropdown-style($color, $bgColor) {
  color: $color;
  background-color: $bgColor !important;
  &:hover {
    color: $color;
    background-color: $bgColor !important;
  }
}

@mixin round-bg(
  $width,
  $height,
  $background-color: transparent,
  $border: false,
  $border-width: 0,
  $border-color: 'transparent'
) {
  display: inline-block;
  width: $width;
  height: $height;
  min-width: $width;
  min-height: $height;
  border-radius: 50%;
  text-align: center;
  background-color: $background-color;
  @if ($border == 'border') {
    border-style: solid;
    border-width: $border-width;
    border-color: $border-color;
    padding: $border-width;
  }
  fa-icon {
    width: 100%;
    height: 100%;
    display: inline-block;
    svg {
      height: 100%;
    }
  }
}

@mixin textElips($max-width) {
  max-width: $max-width;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin multiLineEllipsis($lineHeight: 1.5rem, $lineCount: 1) {
  display: -webkit-box;
  text-overflow: ellipsis;
  min-height: $lineHeight * $lineCount;
  line-height: $lineHeight;
  -webkit-line-clamp: $lineCount;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin columnStyles($cols, $firstCustomCell, $lastCustomCell, $tot: 100%) {
  ::ng-deep {
    visup-th {
      overflow: visible !important;
      white-space: normal !important;
      .th__cell {
        max-width: 99%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      visup-tooltip {
        margin: 0 0.5rem;
        padding: 0 !important;
      }
    }

    visup-td,
    visup-th {
      @include baseCellWidth($cols, ($firstCustomCell + $lastCustomCell), $tot);
      display: flex !important;
      @if ($firstCustomCell != 0) {
        &:first-child {
          @include customCell($firstCustomCell);
        }
      }
      @if ($lastCustomCell != 0) {
        &:last-child {
          @include customCell($lastCustomCell);
        }
      }
    }
  }
}

@mixin baseCellWidth(
  $cols,
  $custom_col_sum,
  $sm_width: 100%,
  $totLength: 100%
) {
  width:  math.div(($totLength - $custom_col_sum), $cols) !important;
  max-width: math.div(($totLength - $custom_col_sum) , $cols) !important;
  // TODO REMOVE DUPLICATE CODE
  @include media-breakpoint-down(sm) {
    flex: 1 1 auto !important;
    width: $sm_width !important;
    max-width: $sm_width !important;
  }
}

@mixin customCell($customWidth, $sm_width: 100%) {
  width: $customWidth !important;
  max-width: $customWidth !important;
  flex: 0 0 auto;
  @include media-breakpoint-down(sm) {
    flex: 1 1 auto !important;
    width: $sm_width !important;
    max-width: $sm_width !important;
  }
}

@mixin fadeInOut() {
  opacity: 0;
  top: 100%;
  &.show {
    animation: fadeIn 0.25s ease-in;
    animation-fill-mode: forwards;
  }
  &.hide {
    animation: fadeOut 0.25s ease-out;
    animation-fill-mode: forwards;
  }
}

@mixin scrollerFadeStyle($displayType: flex, $hasTransition: 'true') {
  .item__container {
    opacity: 0;
    @if ($hasTransition == false) {
      opacity: 1;
    }
    transition: all 0.1s ease-out;
  }
  .item-single {
    display: none;
    width: 100%;
    height: 100%;
  }
  .active {
    .item__container {
      opacity: 1;
    }
    .item-single {
      display: $displayType;
    }
  }
}

@mixin scaleIn() {
  animation: zoomOut 0.25s ease-in;
  animation-direction: reverse;
  transform-origin: center;
}

@mixin scaleOut() {
  animation: zoomOut 0.25s ease-in;
  transform-origin: center;
}

@mixin centerElement($type, $horizontal: true, $vertical: true) {
  @if ($vertical == true) {
    transform: translateY(-50%);
    top: 50%;
  }
  @if ($horizontal == true) {
    transform: translateX(-50%);
    left: 50%;
  }
  @if ($type == absolute) {
    position: absolute;
  } @else {
    position: fixed;
  }
}

@mixin hideScrollBar() {
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin transparentScrollBar() {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba($white, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba($white, 0.3);
    border-radius: 0.625rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.625rem;
    box-shadow: inset 0 0 6px rgba($white, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba($white, 0.3);
    background-color: $dark-grey;
  }
}

@mixin customScrollBar() {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba($black-bg, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba($black-bg, 0.3);
    border-radius: 0.625rem;
    background-color: $grey;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: $grey;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.625rem;
    box-shadow: inset 0 0 6px rgba($black-bg, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba($black-bg, 0.3);
    background-color: #555;
  }
}

@mixin bgStyle() {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba($black-bg, 0.5));
}

@mixin autofillStyle() {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: $white;
    -webkit-box-shadow: 0 0 0 1000px #282828 inset;
    box-shadow: 0 0 0 1000px #282828 inset;
  }
}

@mixin inputStyle() {
  .custom-input {
    @include autofillStyle();
    min-height: 2.8rem;
    height: 2.8rem;
    color: $white;
    background-image: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    position: relative;
    padding: 0.5rem 1rem;
    background-color: #222222;
    transition: border-color 0.4s;
    font-size: 1rem;
    outline: 0;
    border: 0;
    border-radius: 0;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .custom-input:focus {
    border-bottom: 2px solid $primary;
  }

  input[type='text'],
  input[type='number'] {
    border-bottom: 2px solid $grey;
    transition: border-color 0.4s;
  }
  input:invalid {
    border-bottom: 2px solid $red;
  }
}

@mixin fixAlignIcon() {
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .icon--round {
      fa-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@mixin resetDefaultStylesList() {
  padding-left: 0;
  list-style: none;
}

@mixin centerSlide() {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

@mixin activeStyle() {
  color: $primary;
  background-color: $white;
}

@mixin tileStyle() {
  min-width: (100% - 20%);
  border: 2px solid $white;
  border-radius: 0.5rem;
  padding: 2.5rem 0;
  text-align: center;
  box-shadow: none;
  @include media-breakpoint-down(lg) {
    padding: 2.5rem 0;
  }
  @include media-breakpoint-down(md) {
    padding: 1rem 0;
  }
}

@mixin loaderListStyle() {
  position: absolute;
  top: 0;
  top: 1.5rem;
  margin-top: 2rem;
  width: 100%;
  z-index: 9;
}

@mixin fourK {
  @media only screen and (min-width: 160em) {
    @content;
  }
}

@mixin fullhd {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 1000px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-device-width: 576px) {
    @content;
  }
}

@mixin mini-tablet-landscape {
  @media only screen and (max-device-width: 960px) and (max-device-height: 960px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin mini-tablet-portrait {
  @media only screen and (max-device-width: 960px) and (max-device-height: 960px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ipad-mini {
  @media only screen and (min-device-width: 768px) and (max-device-height: 1024px) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ipad-mini-portrait {
  @media only screen and (min-device-width: 768px) and (max-device-height: 1024px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin ipad-air-portrait {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin iphone-landscape {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    @content;
  }
}

@mixin mobileHeight {
  
  @media only screen and (max-height: 568px) {
    @content;
  }
}

@mixin mobileHeightRetina {
  
  @media only screen and (max-device-height: 768px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}