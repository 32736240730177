
// TODO USER LOWERCASE FOR COLORS

$bg-btn: rgba(0,127,255, 0.64);
$bg-transparent-hover: hsla(0,0%,100%,.11);
$bg-color-dark: #1f1f1f;
$bg-color-generic: #212121;
$bg-color: #202020;
$bg-modal: #121212;
$black-bg: #000000;
$black: #111111;
$blue-dark: #007FFF;
$blue: #5C6BC0;
$carousel-height-tile: 440px;
$dark-2: #333333;
$dark-3: #222222;
$dark-grey: #888888;
$dodger-blue: #5A94FF;
$green: #ACDD4D;
$grey: #555555;
$header-x-index: 100;
$lavender: #CD82E5;
$light-bg: #F5F5F5;
$light-blue: #34e5ff;
$pink: #FF0862;
$portage: #8296E5;
$red: #FD0E35;
$red2: #ff003C;
$red3: #FF206E;
$text-gray: rgba(255,255,255,0.3);
$tundora: #4A4A4A;
$turquoise-blue: #34e5ff;
$white: #ffffff;
$yellow: #FDB833;

$primary: $blue-dark;
$success: $green;
$idle: $yellow;
$danger: $red;
$bg-form: $dark-3;

$dialog-width: 640px;
$light-color: rgba($white, 0.48);
$light-yellow: #d1e582;
$mobile-menu-height: 4rem;
