
.toast-container {
  display: flex !important;
  justify-content: center !important;
  .ngx-toastr {
    font-size: 0.875rem;
    background-color: $bg-color !important;
  }
}

// TODO FIND A WAY VIA SERVICE TO AVOID DUPLICATES

.toast-container {
  div {
    &:nth-child(n+1) {
      display: none !important;
    }
    &:first-child {
      display: block !important;
    }
  }
}
