.card {
  min-height: 18rem;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 24px 0 rgba($black-bg,0.24);
  &:focus {
    outline: 0;
  }
}

.card-custom {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 6.25rem;
  margin-top: 2.5rem;
  margin-bottom: 0;
  font-weight: 300;
  @include media-breakpoint-down(md) {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}

.card--gray {
	background-color: transparentize($text-gray, 0.2);
}

.card--black {
  border-radius: 0.5rem;
  background-color: rgba(255,255,255,0.04);
  box-shadow: 2px 2px 24px 0 rgba(0,0,0,0.24);
}

.card__icon {
  font-size: 3rem;
  line-height: 4rem;
  margin-bottom: 1.5rem;
}

.card__title {
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.card__header {
  display: inline-flex;
  align-items: center;
  .card__title {
    @extend .h4;
    margin: 0 1rem 0 0;
  }
}

.card--base {
  display: flex;
  font-weight: 300;
  border-radius: 0.5rem;
  background-color: rgba($white,0.04);
  box-shadow: 2px 2px 1.5rem 0 rgba($black-bg,0.24);
}

.card--rect {
  @extend .card--base;
  padding: 1.5rem 4rem;
  margin-bottom: 1.5rem;
}

.card--mini {
  @extend .card--base;
  padding: 1rem;
  margin-bottom: 2rem;
}