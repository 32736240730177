@mixin greyStyle() {
  opacity: 0.48;
  color: $white;
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: left;
  font-weight: 500;
  letter-spacing: 1px;
}

.text--stop {
  color: $red !important;
}

.text--yellow {
  color: $idle;
}

.text--light {
  margin: 0 0 1.25rem 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  opacity: 0.48;
}

.text--small {
  font-size: 0.75rem;
}

.text--medium {
  font-size: 2rem;
}

// TODO FIND WHY SOMETIMES NOT GETS FROM COMMON STYLES
a:not([href]), a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}
